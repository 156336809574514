@import 'import';
.BFPromoBanner.PromotionBannerCard {
  cursor: auto;

  max-width: 100vw;
  min-height: 80px;
  margin: 20px 0 60px;
  padding: 40px;

  font-size: 17px;
  font-weight: 500;
  color: $white;

  background-color: var(--promo-color-1, $purple);
  border-radius: 8px;

  .BFPromoBannerHeader {
    max-width: 690px;
    margin: 0 auto;

    font-size: 24px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    .BFEyebrow {
      margin-right: 6px;
      color: $light-orange;
    }
  }

  .BFBannerValueProp {
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
  }
}
